import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Result } from "antd";

const Success = (props) => {
  const dispatch = useDispatch();
  const { apiRequest } = useSelector((state) => state);
  const goBack = () => {
    // clear all redux data
    dispatch({ type: "RESET" });
    props.history.push("/");
  };
  return (
    <div className="success">
      <Result
        status="success"
        title="Your request was successfully submitted!"
        subTitle={
          "Your request for UTI treatment has been sent to our providers for review. You will receive an email with a confirmation of your request as well as a payment receipt. "
        }
        extra={[
          <p>
            If your case is approved, you will receive an email with
            instructions to pick up your prescription at the pharmacy.
          </p>,
          <p>
            A confirmation email and receipt will be sent to the email you provided. Please also check your junk mail folder.
          </p>,
          <p
            style={{ color: "rgba(0,0,0)" }}
          >{`Here is your confirmation ID:  ${apiRequest.payment.id}`}</p>,
          <Button type="primary" onClick={() => goBack()}>
            Go to Homepage
          </Button>,
        ]}
      />
    </div>
  );
};

export default Success;
