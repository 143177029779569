import React from "react";
import "./Styles/progress.css";

const ProgressBar = ({ current, total }) => {
  const percent = Math.round((current / total) * 100);
  return (
    <div className="responsive">
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${percent}%` }}
          aria-valuenow={percent}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="progress-info">
        <span>{percent}%</span>
      </div>
    </div>
  );
};

export default React.memo(ProgressBar);
